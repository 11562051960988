<!-- 购物 -->
<template>
  <div style="background: #f2f2f2">
    <Header />
    <Sidebar />
    <div class="prt-nav">
      <div class="container">
        <ul class="breadcrumb">
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li>
            <router-link to="/prolist">产品</router-link>
          </li>
          <li>{{ shopDetails.prodName }}</li>
        </ul>
      </div>
    </div>

    <div class="prt-banner">
      <div class="container">
        <div style="height: 500px">
          <form name="buyForm" id="buyForm" action="javascript:;" method="post">
            <div id="preview">
              <div class="jqzoom" id="spec-n1">
                <img :src="`${shopDetails.pic}`" width="420" height="280" />
                <div class="preview-text">
                  <span>{{ shopDetails.prodName }}</span>
                </div>
              </div>
              <div id="spec-n5">
                <div id="spec-list">
                  <!-- 图片列表 -->
                  <ul class="list-h">
                    <li
                      v-for="(imgitem, imgindex) in (
                        shopDetails.imgs || ''
                      ).split(',')"
                      :key="imgindex"
                    >
                      <img
                        :class="{ activeimg: isActiveimg == imgindex }"
                        @mouseenter="changeImg(imgitem, imgindex)"
                        :src="imgitem"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="prt-meaasge">
              <h1>{{ shopDetails.prodName }}</h1>
              <p>{{ shopDetails.brief }}</p>
              <div class="prt-yen">
                <span
                  >价格
                  <b
                    >&yen;
                    <span
                      v-if="shopDetails.skuList"
                      id="price"
                      style="padding-left: 10px"
                    >
                      {{ price * orderParam.orderItem.prodCount }}</span
                    >
                  </b>
                </span>
              </div>
              <!-- 原始html -->
              <div class="choose-attrs">
                <!--                <div class="choose-attrs-combo-1">-->
                <!--                  <span class="choose-attrs-titles1">套餐：</span>-->
                <!--                  <div class="choose-attrs-comboname">-->
                <!--                    <div-->
                <!--                      id="packagesBox"-->
                <!--                      style="-->
                <!--                        display: flex;-->
                <!--                        flex-direction: row;-->
                <!--                        flex-wrap: wrap;-->
                <!--                        justify-content: flex-start;-->
                <!--                      "-->
                <!--                      class="choose-attrs-item"-->
                <!--                    >-->
                <!--                      <a-->
                <!--                        style="margin-bottom: 5px"-->
                <!--                        v-for="(item, index) in mealList"-->
                <!--                        :key="index"-->
                <!--                        v-trigger-->
                <!--                        :id="'nav' + index"-->
                <!--                        :class="{ 'project-rule-item slt': isActive == index }"-->
                <!--                        @click="chooseMeal(item, index)"-->
                <!--                        v-show="mealList"-->
                <!--                        >{{ item }}-->
                <!--                      </a>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="choose-attrs-combo-1">-->
                <!--                  <span class="choose-attrs-titles1">时长：</span>-->
                <!--                  <div class="choose-attrs-comboname">-->
                <!--                    <div-->
                <!--                      id="packagesBox"-->
                <!--                      style="-->
                <!--                        display: flex;-->
                <!--                        flex-direction: row;-->
                <!--                        flex-wrap: wrap;-->
                <!--                        justify-content: flex-start;-->
                <!--                      "-->
                <!--                      class="choose-attrs-item"-->
                <!--                    >-->
                <!--                      <a-->
                <!--                        style="margin-bottom: 5px"-->
                <!--                        v-for="(item, index) in timeList"-->
                <!--                        :key="index"-->
                <!--                        v-trigger-->
                <!--                        :id="'nav' + index"-->
                <!--                        :class="{ 'project-rule-item slt': isTime == index }"-->
                <!--                        @click="chooseTime(item, index)"-->
                <!--                        v-show="timeList"-->
                <!--                        >{{ item }}-->
                <!--                      </a>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!-- sku 选择区域 开始-->

                <div>
                  <div class="skubox" v-for="name in skuCategoryList">
                    <div class="skubox-text">{{ name }}:</div>
                    <div class="skubox-big">
                      <div
                        class="skuitem"
                        v-for="option in skuCategoryMap[name]"
                        :class="[
                          selectedSkuBitwise[option] ? 'skuitem-isactive' : '',
                          availableSkuBitwise[option] ? '' : 'skuitem-none',
                        ]"
                        @click="selectedSku(option)"
                      >
                        {{ option }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- sku 选择区域 结束-->

                <div>
                  <div id="buyInfoBox">
                    <div></div>
                    <div id="buyNumBox" class="choose-attrs-combo-3">
                      <div
                        class="choose-attrs-titles1"
                        style="float: left; display: block; padding-right: 4px"
                      >
                        购买数量：
                      </div>
                      <div
                        class="ny-number-container choose-attrs-combonum"
                        id="numBox"
                        style="float: left; display: block"
                      >
                        <span class="number-input-box" style="width: 60px">
                          <input
                            maxlength="4"
                            type="text"
                            name="num"
                            class="ny-input-reset ny-number-input"
                            autocomplete="off"
                            v-model="orderParam.orderItem.prodCount"
                            style="width: 30px"
                          />
                          <span class="ny-number-unit">个</span>
                        </span>
                        <span class="ny-number-control">
                          <span class="number-control-up" @click="add()"></span>
                          <span
                            class="number-control-down"
                            @click="sub()"
                          ></span>
                        </span>
                      </div>
                      <div style="clear: both"></div>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- <a
                    type="button"
                    href="javascript:void(0);"
                    class="prt-btn"
                    style="background: #3b3e43; margin-right: 20px"
                    >在线咨询</a
                  > -->
                  <button
                    type="button"
                    style="background: #b60005; cursor: pointer"
                    id="forthwithBtn"
                    class="prt-btn"
                    @click="buyNow"
                  >
                    立即购买
                  </button>
                </div>
                <div class="prt-service">
                  <span>服务保障</span>
                  <img
                    src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/product/icon.png"
                  /><i>平台保障</i>
                  <img
                    src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/product/icon.png"
                  />
                  <i>明码标价</i>
                  <img
                    src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/product/icon.png"
                  />
                  <i>信息安全</i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="prt-detail">
      <div class="container">
        <div class="prt-menu-left-title">
          <h4>
            <a>产品介绍</a> <span class="text-darkred">service details</span>
          </h4>
        </div>
        <div class="prt-menu-right-title">
          <h4>
            <a>相关产品</a> <span class="text-darkred">service details</span>
          </h4>
        </div>
        <div class="prt-menu">
          <!-- 页面 -->
          <div id="pageContent" style="width: 100%">
            <div v-html="shopDetails.content"></div>
          </div>
          <div class="prt-menu-make">
            <div style="width: 880px; margin: 0 auto">
              <h3>为什么选择我们？</h3>
              <p>数十万企业的公共选择，二十多年积淀，帮助企业实现业绩倍增</p>
              <div>
                <div class="prt-menu-make-list">
                  <img
                    src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/product/pr-11.png"
                  />
                  <h5>品质</h5>
                  <p>
                    20多年互联网行业服务经验，拥有数十项专利成果，超过200万用户的选择，互联网应用服务专家。
                  </p>
                </div>
                <div class="prt-menu-make-list">
                  <img
                    src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/product/pr-12.png"
                  />
                  <h5>产品</h5>
                  <p>
                    丰富产品体系、建站服务、各类行业平台、解决方案、域名、空间等基础服务，涵盖PC、手机App等。
                  </p>
                </div>
                <div class="prt-menu-make-list">
                  <img
                    src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/product/pr-13.png"
                  />
                  <h5>服务</h5>
                  <p>
                    专业的团队通过微信群/QQ讨论组/电话等形式为您安排多人的技术团队为您提供售前售后的服务为您提供技术服务。
                  </p>
                </div>
                <div class="prt-menu-make-list">
                  <img
                    src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/product/pr-14.png"
                  />
                  <h5>回报</h5>
                  <p>
                    正规安全的企业服务，充分保障您的业务实践与业务安全，为您的企业带来倍增的业务回报。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="prt-slide">
          <div
            class="prt-slide-seo"
            v-for="(item, index) in hotList"
            :key="index"
          >
            <router-link :to="{ name: 'buy', query: { id: item.prodId } }">
              <div>
                <span class="rec-pro-img-div">
                  <img :src="`${item.pic}`" />
                </span>
                <h4 class="text-overflow">{{ item.prodName }}</h4>
                <p class="text-overflow">{{ item.brief }}</p>
                <a class="text-darkred" style="font-size: 30px">{{
                  item.price
                }}</a>
                <span class="span-color">元/年</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import { removeDuplicate } from "@/utils/userEqu.js";
import { getDetail, createOrder, submitOrder, getShoplist } from "@/api/all.js";
export default {
  components: { Header, Footer, Sidebar },
  data() {
    return {
      //要提交的订单信息
      orderParam: {
        //地址ID，0为默认地址
        addrId: 0,
        //购物车id 数组
        basketIds: [],
        //优惠券id数组
        couponIds: [],
        //立即购买时提交的商品项
        orderItem: {
          //推广员使用的推销卡号
          distributionCardNo: "",
          //产品数量
          prodCount: 1,
          //产品ID
          prodId: "",
          //店铺id
          shopId: "",
          //skuId
          skuId: "",
        },
        //请求序列 本次请求的唯一标识，可以是随机或UUID
        reqSeq: "",
        //用户是否改变了优惠券的选择，如果用户改变了优惠券的选择，则完全根据传入参数进行优惠券的选择
        userChangeCoupon: 0,
      },
      //商品详情
      shopDetails: {},
      //被选中的图片
      isActiveimg: 0,
      //被选中的套餐名称
      isActive: 0,
      //被选中的套餐时间
      isTime: 0,
      //购物车提交订单
      submitOrderParam: {
        couponPeopleIdList: [],
        orderShopParam: [
          {
            remarks: "",
            shopId: 0,
          },
        ],
        reqSeq: "",
      },
      mealList: [],
      timeList: [],
      //套餐和时间的组合
      group: "",
      meal: "",
      tiem: "",
      price: "",
      hotList: [],

      skuList: [],
      skuOptionDict: {},
      skuOptionMoreDict: [],
      selectedSkuBitwise: {},
      // 可用的选项值
      availableSkuBitwise: {},
      skuCategoryMap: {},
      skuCategoryList: [],
    };
  },
  created() {
    //根据id获取用户想买的产品
    this.getMydetail();
  },
  // 自动触发点击事件
  directives: {
    trigger: {
      inserted(el, binging) {
        el.id == "nav0" ? el.click() : null; // 只点击第一个，id是在循环中手动添加的
        // $(el).trigger('click')  // 所有都触发一次,然后就是最后一个
      },
    },
  },
  watch: {
    $route() {
      this.getMydetail();
    },
  },
  methods: {
    //获取商品详情
    getMydetail() {
      this.skuList = [];
      this.skuOptionDict = {};
      this.skuOptionMoreDict = [];
      this.selectedSkuBitwise = {};
      // 可用的选项值
      this.availableSkuBitwise = {};
      this.skuCategoryMap = {};
      this.skuCategoryList = [];
      getDetail({ prodId: this.$route.query.id }).then((res) => {
        // 提交订单参数 生成订单（默认）
        this.orderParam.orderItem.shopId = res.data.shopId;
        this.orderParam.orderItem.prodId = res.data.prodId;
        this.submitOrderParam.reqSeq = Date.now();
        this.submitOrderParam.orderShopParam[0].shopId = res.data.shopId;
        this.submitOrderParam.orderShopParam[0].remarks = Date.now();
        this.price = res.data.price;
        this.isActive = null;
        this.isTime = null;
        this.orderParam.orderItem.prodCount = 1;
        //商品详情
        this.shopDetails = res.data;
        //热点请求数据
        getShoplist({ categoryId: this.categoryId, showChild: true }).then(
          (res) => {
            this.hotList = res.data.rows.slice(0, 10);
          }
        );
        this.skuList = res.data.skuList;

        this.initSku();
      });
    },
    //鼠标移入 改变当前图片
    changeImg(img, index) {
      this.isActiveimg = index;
      this.shopDetails.pic = img;
    },
    //购买数量的加减方法
    add() {
      this.orderParam.orderItem.prodCount += 1;
    },
    sub() {
      if (this.orderParam.orderItem.prodCount <= 1) {
        this.orderParam.orderItem.prodCount = 1;
      } else {
        this.orderParam.orderItem.prodCount -= 1;
      }
    },
    //选中套餐名称
    // chooseMeal(item, index) {
    //   this.timeList = [];
    //   this.meal = [];
    //   for (let newitem of this.shopDetails.skuList) {
    //     if (item == newitem.skuName.split(" ")[1]) {
    //       this.timeList.push(newitem.skuName.split(" ")[0]);
    //       this.meal = item;
    //     }
    //   }
    //   this.isActive = index;
    //   this.isTime = null;
    //   this.orderParam.reqSeq = Date.now();
    //   console.log(this.isTime);
    // },
    //选中时长
    // chooseTime(item, index) {
    //   this.time = [];
    //   this.isTime = index;
    //   this.time = item;
    //   this.group = this.time + " " + this.meal;
    //   console.log(this.group);
    //   for (let sumbit of this.shopDetails.skuList) {
    //     if (sumbit.skuName.trim() == this.group.trim()) {
    //       console.log(sumbit);
    //       this.price = sumbit.price;
    //       this.orderParam.orderItem.skuId = sumbit.skuId;
    //       console.log(this.isTime);
    //     }
    //   }
    // },

    objectKeyFullMatch(o1, o2) {
      const o1ks = Object.keys(o1);
      const o2ks = Object.keys(o2);
      if (o1ks.length !== o2ks.length) {
        return false;
      }
      let ret = {};
      Object.assign(ret, o2);
      Object.assign(ret, o1);
      console.log(ret);
      return Object.keys(ret).length === o2ks.length;
    },

    buyNow() {
      // 取到 SKU 的数据
      const selected = this.skuOptionMoreDict.find((item) =>
        this.objectKeyFullMatch(item.bit, this.selectedSkuBitwise)
      );
      if (!selected) {
        this.$message.error("请选择规格！");
        return;
      }
      this.orderParam.orderItem.skuId = selected.sku.skuId;
      this.orderParam.reqSeq = Date.now();
      this.$store.commit('setOrder',this.orderParam)
      createOrder(this.orderParam).then((res) => {
        console.log(res);
        let couponList = res.data.availableCouponList;
        this.$store.commit("setCouponList", couponList);
        this.$store.commit("submitOrderParam", this.submitOrderParam);
        this.$router.push({
          path: "/issue",
          query: {
            prodId: this.$route.query.id,
            totalCount: this.orderParam.orderItem.prodCount,
            actualTotal: res.data.actualTotal,
          },
        });
      });
    },
    //去重
    unique(arr) {
      return Array.from(new Set(arr));
    },

    // -------------------------------------------- 开始
    /**
     * 初始化SKU 选项列表
     */
    initSku() {
      let nextOffset = 2;
      for (const sku of this.skuList) {
        const chooseArr = sku.properties.split(";");
        let bit = {};
        for (let choo of chooseArr) {
          const kv = choo.split(":");
          const categoryName = kv[0];
          const categoryValue = kv[1];

          if (!this.skuCategoryMap[categoryName]) {
            this.skuCategoryMap[categoryName] = [];
          }
          if (
            !this.skuCategoryMap[categoryName].find(
              (item) => item == categoryValue
            )
          ) {
            this.skuCategoryMap[categoryName].push(categoryValue);
          }

          // if (!this.skuOptionDict[categoryValue]) {
          //   this.skuOptionDict[categoryValue] = nextOffset;
          //   nextOffset *= 2;
          // }

          bit[categoryValue] = 1;
          this.skuOptionDict[categoryValue] = 1;

          // bit |= this.skuOptionDict[categoryValue];
        }
        this.skuOptionMoreDict.push({ bit, sku });
      }
      this.skuCategoryList = Object.keys(this.skuCategoryMap);

      Object.values(this.skuOptionDict).forEach((v) => {
        this.availableSkuBitwise[v] = 1;
      });
    },

    selectedSku(skuName) {
      if (this.selectedSkuBitwise[skuName]) {
        delete this.selectedSkuBitwise[skuName];
      } else {
        this.selectedSkuBitwise[skuName] = 1;
      }

      this.availableSkuBitwise = this.getValidDatadict(this.selectedSkuBitwise);
      if (Object.keys(this.availableSkuBitwise).length == 0) {
        this.selectedSkuBitwise = {};
        this.selectedSku(skuName);
      }
    },

    getValidDatadict(v) {
      const filterlist = this.skuOptionMoreDict.filter((item) =>
        this.getIncludeSelect(item.bit, v)
      );
      if (filterlist.length > 0) {
        this.price = filterlist[0].sku.price;
      }

      const validList = filterlist.map((item) => item.bit);
      const ret = {};
      Object.keys(this.skuOptionDict)
        .filter((k) => validList.find((item) => item[k]))
        .forEach((k) => {
          ret[k] = 1;
        });
      return ret;
    },

    /**
     * 校验选项是否包含，有效选项
     * @param fullKeyObject
     * @param partKeyObject
     * @returns {boolean}
     */
    getIncludeSelect(fullKeyObject, partKeyObject) {
      const partKeys = Object.keys(partKeyObject);
      for (let k of partKeys) {
        if (!fullKeyObject[k]) {
          return false;
        }
      }
      return true;
    },

    // -------------------------------------------- 结束
  },
};
</script>

<style scoped>
.skuitem {
  margin: 10px;
  display: inline-block;
  border: #ccc 1px solid;
  padding: 10px;
}
.skuitem-isactive {
  border: 1px solid #b60005;
  color: #b60005;
}
.skuitem-none {
  background-color: #efefef;
}
.skubox-text {
  display: block;
  width: 74px;
  font-size: 18px;
}
.skubox {
  display: flex;
  align-items: baseline;
}
.skubox-big {
  width: 600px;
}
#pageContent > div > p > img {
  width: 950px !important;
}
.activeimg {
  border: 1px solid darkred;
}
.bigimg {
  width: 959px;
  height: 790px;
}

.top-header {
  position: relative;
}

.prt-banner {
  width: 100% !important;
}

body {
  background: #f2f2f2;
}

.prt-meaasge h1 {
  font-size: 20px;
  margin-top: 10px;
}

.choose-attrs-titles1 {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  margin-top: 10px;
}

.choose-attrs-combo-1,
.choose-attrs-combo-2,
.choose-attrs-combo-3 {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.choose-attrs-comboname,
.choose-attrs-combotime,
.choose-attrs-combonum {
  display: inline-block;
}

.choose-attrs-item a {
  border: 1px solid #ccc;
  padding: 7px 13px;
  display: inline-block;
  white-space: nowrap;
  *display: inline;
  *zoom: 1;
  margin-right: 8px;
}

.choose-attrs-item a:hover {
  color: #b60005;
  border: 1px solid #b60005;
}

#buyTimeBox a {
  border: 1px solid #ccc;
  padding: 7px 13px;
  display: inline-block;
  white-space: nowrap;
  *display: inline;
  *zoom: 1;
  margin-right: 8px;
}

#buyTimeBox a:hover {
  color: #b60005;
  border: 1px solid #b60005;
}

.ny-number-container {
  margin-top: 0;
}

.package-box {
  width: 100%;
  text-align: left;
}

.package-box-titles {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  margin-top: 10px;
}

.package-box-no {
  color: #ccc;
}

.prt-menu-make {
  height: 550px !important;
}

.prt-menu-make-list {
  height: 350px !important;
}

.prt-menu-choose-list {
  height: 300px !important;
}

.slt {
  color: #b60005 !important;
  border: 1px solid #b60005 !important;
}

.prt-slide-seo {
  border: 2px solid transparent;
}

.prt-slide-seo:hover {
  border: 2px solid #b60005;
}

.span-color {
  color: #000;
}

.text-darkred:hover {
  color: #b60005;
}

.active {
  color: red !important;
}
.sku-choose-item {
  color: blue;
}
.buy-btn-gray {
  background: gray;
}

@import "../../assets/static/template/Home/Zkeys/PC/Static/css/module/custom/buy.css";
</style>

<style>
#pageContent img {
  height: auto !important;
  width: 100% !important;
}
</style>
